import { mapGetters } from 'vuex';
import PremiumModal from '@/components/common/PremiumModal';

export default {
  computed : {
    ...mapGetters({
      subscription : 'subscription/subscription',
      user         : 'user/user',
    }),
  },
  methods : {

    /**
     * Check Content
     * @param data
     */
    checkContent(data, contentType) {
      if (data.is_free === 1)
        this.$router.push(data.link);
      else {
        if (this.user.user_type === 1 ||
          this.subscription.class_name === 'free')
          this.showPremiumModal(contentType);
        else
          this.$router.push(data.link);
      }
    },

    /**
     * Show Premium Modal
     * @param contentType
     */
    showPremiumModal(contentType) {
      this.$modal.show(PremiumModal, {
        contentType : contentType,
        userType    : this.user.user_type,
      }, {
        height   : 'auto',
        adaptive : true,
        classes  : 'premium-modal',
      });
    },
  },
}