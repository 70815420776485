<template>
  <div class="dialog">
    <div class="dialog-content">
      <!-- Title -->
      <h4 class="title">
        Premium <span class="content-title">{{ contentType }}</span>
      </h4>

      <!-- Text -->
      <p class="text" v-if="userType !== 1">
        <span v-if="contentType === 'feature'">
          This feature is for subscribers. Subscribe to get full access of this
          feature.
        </span>
        <span v-else>
          This {{ contentType }} is for subscribers. Subscribe for access today.
        </span>
      </p>
      <p class="text" v-else>
        You have to be 18 year old and above to subscribe. Thank you.
        <!-- Unable to access this {{ contentType }}. -->
      </p>

      <!-- Buttons -->
      <b-row v-if="userType !== 1">
        <b-col cols="6">
          <b-button
            class="btn-h3-orange"
            block
            @click="redirectToSubsPlan">
            Continue
          </b-button>
        </b-col>
        <b-col cols="6">
          <b-button
            class="btn-h3-red"
            block
            @click="$emit('close')">
            Cancel
          </b-button>
        </b-col>
      </b-row>

      <b-row class="justify-content-center" v-else>
        <b-col cols="6">
          <b-button
            class="btn-h3-red"
            block
            @click="$emit('close')">
            Close
          </b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  export default {
    props : {
      contentType : {
        type     : String,
        required : true,
        default  : 'content',
      },
      userType : {
        type     : Number,
        required : true,
      },
    },
    methods : {

      /**
       * Redirect to Subscription Plan page
       */
      redirectToSubsPlan() {
        this.$emit('close');
        this.$router.push('/plans');
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/settings";

  .dialog {
    padding: 1em;
    background-image: url(../../assets/images/bg_banner3_alt.png);
    background-position: top;
    border-radius: 8px;
  }

  .dialog-content {
    padding-left: 1rem;
    padding-right: 1.5rem;
    text-align: center;
    background-color: rgba(255,255,255,0.75);
  }

  .title {
    color: #fff;
    font-family: "WickedMouse";
    font-size: 1.75em !important;
    -webkit-text-stroke: 1px #499518 !important;
  }

  .text {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #499518 !important;
    font-family: "ArialRoundedMTBold";
    font-size: 1.25em !important;
  }

  .content-title {
    text-transform: capitalize;
  }
</style>